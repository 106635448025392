//toCommon
import "./NotFound.css";
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import leftTopTrick from "../../assets/images/leftTopTrick.svg";
import rightTopTrick from "../../assets/images/rightTopTrick.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useContext } from "react";
import { ModeCtx } from "../../context/modeCtx";
import { createBrowserHistory } from "history";
import CongigContext from "../../context/ConfigContext";
const history = createBrowserHistory({ window });

const HOME_URL = "https://galaxis.xyz/#/";

const sx = {
  root: {
    position: "relative",
    width: "100%",
    pt: "150px",
    pb: "115px",
    mb: "100px",
    overflow: "hidden",
    transition: "all .3s",
    backgroundColor: "#FAF9F5",
    px: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  connectBtn: {
    borderRadius: "5px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    backgroundColor: "unset",
    border: "2px solid #F64C04",
    color: "#F64C04",
    marginTop: "20px",
    marginLeft: "5px",
    marginBottom: "20px",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#ffffff",
    },
  },
};

const TopSectionDividers = () => (
  <Box
    style={{
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <img src={leftTopTrick} style={{ maxHeight: "50px" }} alt="" />
    <img src={rightTopTrick} style={{ maxHeight: "50px" }} alt="" />
  </Box>
);
const BottomSectionDividers = () => (
  <Box
    style={{
      display: "flex",
      transform: "rotateX(180deg)",
      justifyContent: "space-between",
    }}
  >
    <img src={leftTopTrick} style={{ maxHeight: "50px" }} alt="" />
    <img src={rightTopTrick} style={{ maxHeight: "50px" }} alt="" />
  </Box>
);

const getColor = (modeCtx) => {
  return modeCtx.lightTheme ? "#000" : "#FFF";
};

const NotFound = () => {
  const modeCtx = useContext(ModeCtx);
  const config = useContext(CongigContext);
  const navigateBack = () => {
    history.push(HOME_URL);
  };

  return (
    <>
      <Header />
      <Box
        sx={sx.root}
        id="homeContainer"
        style={{ backgroundColor: !modeCtx.lightTheme && "#050D15" }}
      >
        <div
          className="borderupcoming"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <TopSectionDividers />

          <div className="not-found-container">
            <span style={{ color: getColor(modeCtx) }}>404</span>
            <p
              style={{
                textAlign: "center",
                fontFamily: "poppins-semibold",
                fontSize: "50px",
                color: "#F64C04",
              }}
            >
              {" "}
              Oops!{" "}
            </p>
            <Typography style={{ margin: "20px" }}>
              Sorry! The page you requested could not be found.
            </Typography>
            <Button variant="banner" sx={sx.connectBtn} onClick={navigateBack}>
              GET BACK TO HOME
            </Button>
          </div>
          <BottomSectionDividers />
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default NotFound;
