import React from "react";
import { Button } from "@mui/material";

const sx = {
  bookButton: {
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#F64C04",
      opacity: 0.5,
    },
    border: "2px solid #F64C04",
    backgroundColor: "unset",
    color: "var(--orangePrimary)",
    margin: "0px",
    boxShadow: "unset",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "var(--orangePrimary)",
      color: "#fafaf2",
    },
    "&:active": {
      border: "2px solid var(--orangePrimary)",
      backgroundColor: "var(--orangePrimary)",
      color: "#fafaf2",
    },
  },
};

const HomeButton = ({ url }) => {
  return (
    <Button
      sx={sx.bookButton}
      variant="contained"
      style={{
        marginBottom: "50px",
      }}
      href={url}
    >
      HOME
    </Button>
  );
};

export default HomeButton;
