/* toCommon */
import "./EmailPopup.css";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useContext } from "react";
import close from "../assets/images/black-close.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toast";
import { ModeCtx } from "../context/modeCtx";
import ConfigContext from "../context/ConfigContext";

const BP1 = "@media (max-width: 490px)";
const sx = {
  root: {
    maxWidth: "553px",
    maxHeight: "710px",
    width: "100%",
    height: "100%",
    border: "2px solid #050000",
    borderRadius: "27px",
    backgroundColor: "#FFFFFF",
    position: "relative",
    p: "20px",
    overflow: "auto",
  },
  close: {
    position: "absolute",
    top: "35px",
    right: "31px",
    cursor: "pointer",
    [BP1]: {
      top: "15px",
      right: "15px",
    },
  },
  title: {
    fontSize: "38px",
    fontFamily: "Poppins-semibold",
    textAlign: "center",
    color: "#F64C04!important",
    mt: "25px",
    mb: "30px",
    [BP1]: {
      marginBottom: "15px",
    },
  },
  form: {
    px: "68px",
    [BP1]: {
      px: "unset",
    },
  },
  textarea: {
    maxWidth: "416px",
    maxHeight: "180px",
    minHeight: "100%",
    width: "100%",
    height: "100%",
    border: "2px solid #050000",
    borderRadius: "6px",
    marginTop: "10px",
    padding: "17px 20px 17px 20px",
    fontSize: "12px",
    fontFamily: "Poppins",
    resize: "none",
    whiteSpace: "pre-wrap",
    [BP1]: {
      marginBottom: "unset",
    },
  },
  option: {
    borderRadius: "15px",
    marginBottom: "5px",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  input: {
    width: "100%",
    height: "51px",
    border: "2px solid #050000",
    borderRadius: "6px",
    marginTop: "10px",
    padding: "17px 20px 17px 20px",
    fontSize: "12px",
    fontFamily: "Poppins",
    "&:focus": {
      border: "2px solid #F64C04",
      outline: "none",
    },
  },
  button: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    minHeight: "57px",
    fontFamily: "poppins-semibold",
    fontSize: "14px",
    color: "#F64C04",
    border: "2px solid #F64C04",
    mt: "20px",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#FAF9F5",
    },
    [BP1]: {
      mt: "15px",
    },
  },
  recapcha: {
    fontSize: "12px",
    fontFamily: "Poppins",
    lineHeight: "15px",
    mt: "15px",
    "& a": {
      textDecoration: "none",
      color: "#F64C04",
    },
  },
  dropArrow: {
    position: "absolute",
    top: "25px",
    right: "15px",
    color: "#F64C04",
  },
  error: {
    fontSize: "10px",
    fontFamily: "Poppins",
    color: "#F50808",
  },
  darkForm: {
    px: "68px",
    color: "#fff",
    "& input": {
      backgroundColor: "transparent",
      borderColor: "#F64C04!important",
      color: "#fff",
      "&::placeholder": {
        color: "#fff9",
      },
    },
    ul: {
      background: "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))",
    },
    li: {
      "&:hover": {
        background: "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))",
      },
    },
    "& textarea": {
      backgroundColor: "transparent",
      borderColor: "#F64C04!important",
      color: "#fff",
      "&::placeholder": {
        color: "#fff9",
      },
    },
    [BP1]: {
      px: "unset",
    },
  },
};

const OPTIONS = [
  {
    id: 1,
    title: "General Information",
  },
  {
    id: 2,
    title: "Digital Collectible Release",
  },
  {
    id: 3,
    title: "Launchpad",
  },
  {
    id: 4,
    title: "Ether Cards",
  },
  {
    id: 5,
    title: "Partnership",
  },
];

const EmailPopup = ({ onClose }) => {
  const config = useContext(ConfigContext);
  const [initialValue, setInitialValue] = useState({
    name: "",
    email: "",
    message: "",
    subject: OPTIONS[0].title,
  });

  const [displayOptions, setDisplayOptions] = useState(OPTIONS);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [displayValue, setDisplayValue] = useState("");
  const [loading, setLoading] = useState(false);
  const modeCtx = useContext(ModeCtx);

  const handleOnClick = (e) => {
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config.RECAPCHA_SITEKEY, { action: "submit" })
        .then((token) => {
          formikHandleSubmit(token, formik.values);
        });
    });
  };
  const formikHandleSubmit = (token, values) => {
    let tmpmessage = values.message;
    let message = tmpmessage.replace(/\n/g, "<br>\n");

    let body = {
      name: values.name,
      email: values.email,
      subject: values.subject,
      message: message,
      token: `${token}`,
    };
    axios
      .post(`${config.API_URL}/email`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("Email sent successfully.");
        onClose();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong, sorry.");
        onClose();
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, "The name must contain minimum 3 characters")
        .max(37, "The name is longer than 37 characters")
        .required("Name is required"),
      email: Yup.string()
        .required("The email is required.")
        .email("Invalid email format."),
      message: Yup.string()
        .required("The message is required.")
        .min(50, "The message must contain minimum 50 characters")
        .max(3000, "The message is longer than 3000 characters"),
      subject: Yup.string().required("The subject is required"),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    onChange: (v) => {
      console.log("CHANGEVALS", v.values);
    },
    onSubmit: handleOnClick,
  });

  return (
    <Box
      sx={sx.root}
      style={{
        background: modeCtx.lightTheme
          ? "#fff"
          : "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))",
      }}
      onClick={() => {
        if (dropdownVisible) setDropdownVisible(!dropdownVisible);
      }}
    >
      <Box
        sx={sx.close}
        onClick={() => {
          onClose();
        }}
      >
        <img
          src={close}
          alt="anything"
          className={` ${modeCtx.lightTheme ? "" : "dark-close-button"}`}
        />
      </Box>
      <Typography sx={sx.title}>Contact Us</Typography>

      <Box sx={modeCtx.lightTheme ? sx.form : sx.darkForm}>
        <form onSubmit={formik.handleSubmit}>
          <input
            id="contactName"
            type="text"
            placeholder="NAME"
            name="name"
            value={formik.values.name}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            style={{
              ...sx.input,
              border:
                formik.errors.name && formik.touched.name
                  ? "2px solid #F50808"
                  : "2px solid #050000",
            }}
          />
          {formik.errors.name && formik.touched.name && (
            <Box sx={sx.error}>{formik.errors.name}</Box>
          )}
          <input
            id="contactEmail"
            type="email"
            placeholder="EMAIL"
            name="email"
            style={{
              ...sx.input,
              border:
                formik.errors.email && formik.touched.email
                  ? "2px solid #F50808"
                  : "2px solid #050000",
            }}
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.errors.email && formik.touched.email && (
            <Box sx={sx.error}>{formik.errors.email}</Box>
          )}
          <div className={`floating-container`}>
            <input
              type="select"
              // className={`select-input val-${value + ''}- ${value !== '' ? 'has-value' : ''}`}
              id={"contactSelect"}
              name="subject"
              onClick={(e) => {
                setDropdownVisible(!dropdownVisible);
              }}
              readOnly
              style={{
                ...sx.input,
              }}
              placeholder="SUBJECT"
              value={formik.values.subject}
            />
            <ArrowDropDownIcon
              sx={sx.dropArrow}
              style={{
                transform: dropdownVisible ? `rotate(180deg)` : "rotate(0)",
              }}
              onClick={(e) => {
                setDropdownVisible(!dropdownVisible);
              }}
            />
            <ul className={`select-dropdown ${dropdownVisible && "opened"}`}>
              {displayOptions.map((o, i) => {
                return (
                  <li
                    key={o.id}
                    className={`${
                      formik.values.subject === o.title ? "selected" : ""
                    }`}
                    onClick={(e) => {
                      setDropdownVisible(false);
                      setDisplayValue(o.title);
                      // setInitialValue({subject: o.title})
                      formik.setFieldValue("subject", o.title);
                    }}
                  >
                    {o.title}
                  </li>
                );
              })}
            </ul>
          </div>
          {formik.errors.subject && formik.touched.subject && (
            <Box sx={sx.error}>{formik.errors.subject}</Box>
          )}
          <textarea
            name="message"
            placeholder="MESSAGE"
            id="messsage"
            rows="10"
            style={{
              ...sx.textarea,

              border:
                formik.errors.message && formik.touched.message
                  ? "2px solid #F50808"
                  : "2px solid #050000",
            }}
            value={formik.values.message}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.errors.message && formik.touched.message && (
            <Box sx={sx.error}>{formik.errors.message}</Box>
          )}
          <Typography sx={sx.recapcha} variant="bannerText">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">
              {" "}
              Privacy Policy
            </a>{" "}
            and
            <a href="https://policies.google.com/terms">
              {" "}
              Terms of Service
            </a>{" "}
            apply.
          </Typography>

          <Button
            sx={sx.button}
            type="submit"
            disabled={loading ? true : false}
          >
            SEND MESSAGE
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default EmailPopup;
