import { useState, useEffect } from "react";

const useSignerAddress = (wallet) => {
  const [signerAddress, setSignerAddress] = useState(null);

  const getSignerAddress = (wallet) => {
    if (!wallet) {
      return null;
    }
    return wallet.accounts[0].address;
  };

  useEffect(() => {
    const addr = getSignerAddress(wallet);
    setSignerAddress(addr);
  }, [wallet]);

  return [signerAddress, setSignerAddress];
};

export default useSignerAddress;
