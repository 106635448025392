import { ethers } from "ethers";

import { default as CommunityList } from "../contracts/CommunityList.json";
import { default as CommunityRegistry } from "../contracts/CommunityRegistry.json";
import { default as GalaxisRegistry } from "../contracts/GalaxisRegistry.json";
import { default as MasterRegistry } from "../contracts/MasterRegistry.json";

const NFTToolbox = require("../contracts/NFTToolbox.json");
const ECRegistryV3 = require("../contracts/ECRegistryV3c.json");
const AppointmentStorage = require("../contracts/UTAppointmentStorage.json");
const AppointmentController = require("../contracts/UTAppointmentController.json");

const contracts = {
  NFTToolbox,
  ECRegistryV3,
  AppointmentStorage,
  AppointmentController,
  CommunityList,
  CommunityRegistry,
  GalaxisRegistry,
  MasterRegistry,
};

// const CHAIN_ID = config.DEPLOYED_CHAIN_ID;
// const RPC_URL = config.RPC_URL;

const getContract = async (contractName, address) => {
  const config = JSON.parse(localStorage.getItem("appConfig"));
  const RPC_URL = config.RPC_URL;
  const provider = new ethers.providers.JsonRpcProvider(RPC_URL);

  return new ethers.Contract(address, contracts[contractName].abi, provider);
};

export { getContract, contracts };
