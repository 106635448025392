import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MeetingClaim from "../../components/MeetingClaim/MeetingClaim";
import { getRedeemState } from "../../utils/helper";
import useSignerAddress from "../../hooks/useSignerAddress";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { toast } from "react-toast";
import { useUrlParams } from "galaxis-utraits-client-common";
import ThankYou from "../../components/ThankYou/ThankYou";
import CardFrame from "../../components/CardFrame";
import DisplayBox from "../../components/DisplayBox";
import PageContext from "../../components/PageContext/PageContext";
import DisplayTxBox from "../../components/DisplayTxBox";
import DisplayRestart from "../../components/DisplayRestart";
import ConfigContext from "../../context/ConfigContext";
import useContractService from "../../hooks/useContractService";
import useIsWrongQueryParams from "../../hooks/useIsWrongQueryParams";
import TxProgressModal from "../../components/TxProgressModal";
//import initOnboard from "../../services/onboard";

//import initOnboard from "../../services/onboard";

// ----------------------------------------- Redeem statuses -----------------------------------------------------------
// ERROR                            - Error in backend
// MISSING                          - Trait is not granted for this card
// OPEN                             - Trait in initial in backend
// UNCONFIRMED                      - Trait burn was started according to the backend but not confirmed yet
// MANUALLY_BURNED                  - Trait was manually burned on chain (not with this app)
// CONFIRMED                        - Trait burn was confirmed by the backend
// SIGN                             - Need to request state again from backend (for sensitive infomation) - NOT USED!
// ---------------------------------------------------------------------------------------------------------------------

//initOnboard();

const Home = () => {
  const [tokenId, traitId] = useUrlParams();
  const isWrongQueryParams = useIsWrongQueryParams(tokenId, traitId);
  const contractService = useContractService(tokenId, traitId);
  const [contractServiceInitialized, setContractServiceInitialized] =
    useState(false);
  const navigate = useNavigate();
  const config = useContext(ConfigContext);
  const [isReading, setIsReading] = useState(false);

  const DEPLOYED_CHAIN_ID = +config.CHAIN_ID;
  const APP_TRAIT_TYPE = +config.APP_TRAIT_TYPE;

  useEffect(() => {
    if (isNaN(traitId) || traitId < 0 || isNaN(tokenId) || tokenId < 0) {
      navigate("/*", { replace: true });
    }
  }, [navigate, tokenId, traitId]);

  const [traitName, setTraitName] = useState(null);
  const [traitType, setTraitType] = useState(null);
  const [pretested, setPretested] = useState(false);
  const [owner, setOwner] = useState(false);
  const [redeemState, setRedeemState] = useState(null);
  const [txHash, setTxHash] = useState(null);
  const [message, setMessage] = useState({
    message: null,
    view: null,
  });
  const [isLocallyValidatedUser, setIsLocallyValidatedUser] = useState(false);
  const [isWrongChain, setIsWrongChain] = useState(true);

  const [{ wallet, connecting }, connect] = useConnectWallet();
  const [{ chains, connectedChain }, setChain] = useSetChain();

  const [signerAddress] = useSignerAddress();

  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState("");

  const VIEW = {
    BOX: 1,
    THANK_YOU: 2,
  };

  const msg = (message, view, variant = null) => {
    return {
      message: message,
      view: view,
      variant: variant,
    };
  };

  const handleRestart = async () => {
    setRedeemState("OPEN");
  };

  useEffect(() => {
    const doit = async () => {
      const previousWallet = window.localStorage.getItem("selectedWallet");
      if (previousWallet) {
        // await connect({ autoSelect: previousWallet });
        await connect({
          autoSelect: { label: previousWallet, disableModals: true },
        });
      } else {
        await connect();
      }
    };

    if (config) {
      doit();
    }
  }, [connect, config]);

  useEffect(() => {
    if (config && connectedChain && DEPLOYED_CHAIN_ID) {
      setIsWrongChain(
        connectedChain && parseInt(connectedChain.id, 16) !== DEPLOYED_CHAIN_ID
      );
    }
  }, [connectedChain, config, DEPLOYED_CHAIN_ID]);

  useEffect(() => {
    const isUser =
      !!pretested &&
      !!owner &&
      !!wallet &&
      !!connectedChain &&
      parseInt(connectedChain?.id, 16) === DEPLOYED_CHAIN_ID;

    setIsLocallyValidatedUser(isUser);
  }, [pretested, owner, wallet, connectedChain, DEPLOYED_CHAIN_ID]);

  useEffect(() => {
    function DisplayBoxMessage() {
      if (isWrongQueryParams) {
        setMessage(msg("Wrong token or trait!", VIEW.BOX));
        return;
      }

      if (!wallet) {
        setMessage(msg("Please connect to a wallet to start!", VIEW.BOX));
        return;
      }

      if (isWrongChain) {
        setMessage(msg("Wrong chain!", VIEW.BOX));
        return;
      }

      if (isLocallyValidatedUser && traitType && traitType !== APP_TRAIT_TYPE) {
        setMessage(
          msg("This application is not valid for this trait type!", VIEW.BOX)
        );
        return;
      }

      if (redeemState === "MISSING") {
        setMessage(msg("This trait is not active on you card!", VIEW.BOX));
        return;
      }

      if (isLocallyValidatedUser && redeemState === "UNCONFIRMED") {
        setMessage(
          msg(
            "Your appointment request has been submitted! Transaction not yet confirmed.",
            VIEW.THANK_YOU,
            "restart"
          )
        );
        return;
      }

      if (isLocallyValidatedUser && redeemState === "CONFIRMED") {
        setMessage(
          msg(
            "Your appointment date was submitted! Transaction confirmed. A mail was sent to you with the meeting link.",
            VIEW.THANK_YOU
          )
        );
        return;
      }

      if (redeemState === "SIGN") {
        setMessage(
          msg("Please sign the message to prove you are the owner!", VIEW.BOX)
        );
        return;
      }

      if (pretested && !owner && wallet) {
        setMessage(
          msg("You could not prove your ownership of this token!", VIEW.BOX)
        );
        return;
      }

      setMessage(msg(null, null));
    }

    DisplayBoxMessage();
  }, [
    VIEW.BOX,
    VIEW.THANK_YOU,
    APP_TRAIT_TYPE,
    isLocallyValidatedUser,
    isWrongChain,
    isWrongQueryParams,
    owner,
    pretested,
    redeemState,
    traitName,
    traitType,
    wallet,
  ]);

  // ---- Read url parameters and connect wallet ----
  useEffect(() => {
    const doit = async () => {
      setIsReading(true);
      try {
        await contractService.init(traitId);

        if (contractService.isValidTrait(traitId)) {
        } else {
          console.error("Invalid trait");
        }

        setContractServiceInitialized(true);
        setIsReading(false);
      } catch (e) {
        setTraitType("ERROR");
        setMessage(msg("Wrong token or trait!", VIEW.BOX));
        setIsReading(false);
        return;
      }
    };

    doit();
  }, [VIEW.BOX, contractService, traitId]);

  // ---- Check chain and pre-validate user 'locally' ----
  useEffect(() => {
    const doit = async () => {
      // console.log('--3--', traitId, tokenId, wallet, connectedChain);

      // Switch network if needed
      setIsReading(true);
      if (
        connectedChain &&
        parseInt(connectedChain.id, 16) !== DEPLOYED_CHAIN_ID
      ) {
        setChain({ chainId: `0x${DEPLOYED_CHAIN_ID.toString(16)}` });
      }

      // Save currently selected wallet for later reuse
      if (wallet) {
        window.localStorage.setItem("selectedWallet", wallet.label);
      }

      // Initiate _local_ validation (this can be faked, but later we will verify it by signature anyways)
      // This makes the process more user friendly for the 'normal' users
      if (
        wallet &&
        connectedChain &&
        traitId !== null &&
        tokenId !== null &&
        parseInt(connectedChain.id, 16) === DEPLOYED_CHAIN_ID
      ) {
        const tokenIds = await contractService.getTokens(
          wallet.accounts[0].address
        );
        // console.log('Address:', wallet.accounts[0].address);
        // console.log('TokenIds:', tokenIds);
        // console.log('TokenId:', tokenId);
        // if (tokenIds?.includes(tokenId)) {
        if (tokenIds?.includes(tokenId)) {
          setPretested(true);
          setOwner(true);
        } else {
          setPretested(true);
          setOwner(false);
        }

        // Get the name of the current trait
        // console.log('index', index, 'traitId', traitId);
        setTraitName(contractService.traitName());
        setTraitType(contractService.traitType());
        setIsReading(false);
      }
    };
    if (contractService && contractServiceInitialized) {
      doit();
    }
  }, [
    wallet,
    connectedChain,
    traitId,
    tokenId,
    setChain,
    contractService,
    DEPLOYED_CHAIN_ID,
    contractServiceInitialized,
  ]);

  // ---- Once the user is pre-validated, check if there is a redeem record
  useEffect(() => {
    const doit = async () => {
      if (pretested && owner && traitType === APP_TRAIT_TYPE) {
        setIsReading(true);
        // Read state from server (based on backend db data)
        // Read trait state from blockchain
        let [stateObj, traitValue] = await Promise.all([
          getRedeemState(tokenId, traitId, wallet),
          contractService.getTraitValue(tokenId),
        ]);
        console.log("statobject", stateObj);
        if (stateObj?.state === "ERROR") {
          toast.error(
            stateObj.message
              ? stateObj.message
              : "Can not get redeem state! Please try again later!"
          );
          setIsReading(false);
          return;
        }

        // Override state from blockchain
        switch (traitValue) {
          case 0:
            stateObj = { state: "MISSING", txHash: null };
            break;
          case 1:
            if (!["OPEN", "UNCONFIRMED"].includes(stateObj.state)) {
              stateObj = { state: "OPEN", txHash: stateObj.txHash };
            }
            break;
          case 2:
            if (!["MANUALLY_BURNED", "CONFIRMED"].includes(stateObj.state)) {
              stateObj = { state: "MANUALLY_BURNED", txHash: stateObj.txHash };
            }
            break;
          default:
        }

        setRedeemState(stateObj.state);
        setTxHash(stateObj.txHash);
        setIsReading(false);
      }
    };

    doit();
  }, [
    pretested,
    owner,
    traitType,
    traitId,
    tokenId,
    wallet,
    contractService,
    APP_TRAIT_TYPE,
  ]);

  return (
    <PageContext>
      <DisplayBox
        message={"Connecting..."}
        displayWhen={isReading || connecting}
        showSpinner={isReading || connecting}
      />
      <CardFrame
        isOpen={!connecting && !isReading && message.message}
        footer={
          <>
            <DisplayTxBox
              txHash={txHash}
              displayWhen={
                txHash && !["OPEN", "UNCONFIRMED"].includes(redeemState)
              }
            />
            <DisplayRestart
              handleRestart={handleRestart}
              displayWhen={redeemState === "UNCONFIRMED"}
            />
          </>
        }
      >
        {message.view === VIEW.BOX && (
          <DisplayBox
            message={message.message}
            displayWhen={true && !connecting && !isReading && message.message}
          />
        )}
        {message.view === VIEW.THANK_YOU && (
          <ThankYou message={message.message} />
        )}

        {/* <DisplayBox message={"Connecting..."} displayWhen={connecting} /> */}
      </CardFrame>
      <TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />
      {/* {!txInProgress && ( */}
      <CardFrame
        isOpen={
          isLocallyValidatedUser &&
          !connecting &&
          !isReading &&
          ["OPEN", "MANUALLY_BURNED"].includes(redeemState)
        }
      >
        <MeetingClaim
          isOpen={true}
          tokenId={tokenId}
          traitId={traitId}
          signerAddress={signerAddress}
          redeemState={redeemState}
          setRedeemState={setRedeemState}
          setTxHash={setTxHash}
          txInProgress={txInProgress}
          setTxInProgress={setTxInProgress}
          txEtherScan={txEtherScan}
          setTxEtherScan={setTxEtherScan}
          contractService={contractService}
        />
      </CardFrame>
      {/* )} */}
    </PageContext>
  );
};

export default Home;
