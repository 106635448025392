import React, { useContext, useRef, useState, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import Header from "../../components/Header";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import axios from "../../api";
import useModCtx from "../../hooks/useModCtx";
import { blue, grey } from "@mui/material/colors";
import ThemeBox from "../../components/ThemeBox";
import { ThemedBox } from "galaxis-utraits-client-common";
//import { withStyles } from "@material-ui/core/styles";

/*
type ProcessedEvent = {
    event_id: number or string;
    title: string;
    start: Date;
    end: Date;
    disabled?: boolean;
    color?: string;
    editable?: boolean;
    deletable?: boolean;
    draggable?: boolean;
    allDay?: boolean;
    } 
*/

const styles = (theme) => ({
  header: {
    position: "relative",
    backgroundColor: "red",
    color: "red",
  },
});

const sx = {
  root: {
    position: "relative",
    width: "100%",
    pt: "150px",
    overflow: "hidden",
    transition: "all .3s",
    backgroundColor: "#FAF9F5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "strech",
    maxWidth: "1140px",
    mx: "auto",
  },
};

const SchedulerAdmin = (props) => {
  const [events, setEvents] = useState([]);
  const [query, setQuery] = useState();
  const [openColor, setOpenColor] = useState();
  const [bookedColor, setBookedColor] = useState({ color: "#F64C04" });
  const baseTheme = useTheme();
  const modeCtx = useModCtx();
  const { classes } = props;

  //   useEffect(() => {
  //     setOpenColor({ color: modeCtx.lightTheme ? "#66696e" : "#488c29" });
  //   }, [modeCtx.lightTheme]);

  //   useEffect(() => {
  //     function getResolvedEvent(event) {
  //       return {
  //         id: event.id,
  //         start: new Date(event.start),
  //         end: new Date(event.end),
  //         event_id: event.id,
  //         title: event.title,
  //         // ...(event.status === "open" && openColor),
  //         // ...(event.status === "booked" && bookedColor),
  //         status: event.status,
  //       };
  //     }
  //     const fetchRemote = async (query) => {
  //       axios.get("/api/events", { params: query }).then((response) => {
  //         setEvents(response.data.events.map((ev) => getResolvedEvent(ev)));
  //       });
  //     };
  //     fetchRemote(query);
  //   }, [bookedColor, modeCtx.lightTheme, openColor, query]);

  const calendarTheme = (theme) =>
    createTheme({
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "&.MuiButton-text": {
                color: "#209EE6",
                ":hover": {
                  backgroundColor: "#209EE6",
                  borderColor: "#209EE6",
                  color: "white",
                },
              },
              //   "&.MuiPickersDay-root": {
              //     color: "black",
              //   },
            },
          },
        },
        //     MuiPickersCalendarHeader: {
        //       styleOverrides: {
        //         label: {
        //           color: "black",
        //         },
        //       },
        //     },
        //     MuiClock: {
        //       styleOverrides: {
        //         root: {
        //           color: "black",
        //         },
        //       },
        //     },
        //     MuiClockNumber: {
        //       styleOverrides: {
        //         root: {
        //           color: "black",
        //         },
        //       },
        //     },
        //     // Name of the component
        //     MuiButton: {
        //       styleOverrides: {
        //         // Name of the slot
        //         root: {
        //           // Some CSS
        //           fontSize: "1rem",
        //           borderColor: grey[100],
        //         },
        //         text: {
        //           color: "black",
        //         },
        //         textPrimary: {
        //           color: "black",
        //         },
        //         // "&:hover": {
        //         //   color: baseTheme.palette.primary.main,
        //         // },
        //         //},
        //         // textPrimary: {
        //         //   //color: baseTheme.palette.primary.main,
        //         // },
        //       },
        //     },
        //     MuiTypography: {
        //       styleOverrides: {
        //         root: {
        //           color: "black",
        //         },
        //         subtitle2: {
        //           color: "white",
        //         },
        //       },
        //     },
      },
    });

  //   function resolveEvent(event) {
  //     return {
  //       id: event.id,
  //       start: new Date(event.start),
  //       end: new Date(event.end),
  //       event_id: event.id,
  //       title: event.title,
  //       ...(event.status === "open" && getOpenEventColor()),
  //       ...(event.status === "booked" && getBookedEventColor()),
  //     };
  //   }

  function resolveEvent(event) {
    return {
      id: event.id,
      start: new Date(event.start),
      end: new Date(event.end),
      event_id: event.id,
      title: event.title,
      ...(event.status === "booked"
        ? { color: grey[500], disabled: true }
        : undefined),
      status: event.status,
    };
  }

  const fetchRemote = async (query) => {
    return axios.get("/api/events", { params: query }).then((response) => {
      return response.data.events.map((ev) => resolveEvent(ev));
    });
  };

  const handleConfirm = async (event, action) => {
    if (action === "edit") {
      return await axios
        .put(`/api/event/${event.event_id}`, {
          data: event,
        })
        .then((response) => {
          return resolveEvent(response.data);
        });
    } else if (action === "create") {
      return await axios.post("/api/events", event).then((response) => {
        return resolveEvent(response.data);
      });
    }
  };

  const handleDelete = async (deletedId) => {
    // Simulate http request: return the deleted id
    return axios.delete(`/api/event/${deletedId}`).then((response) => {
      return deletedId;
    });
  };

  const handleOnEventDrop = async (droppedOn, updatedEvent, originalEvent) => {
    console.log(droppedOn, updatedEvent, originalEvent);

    return await axios
      .put(`/api/event/${updatedEvent.event_id}`, {
        data: updatedEvent,
      })
      .then((response) => {
        return resolveEvent(response.data);
      });
  };

  //   const fetchRemote = async (query) => {
  //     console.log({ query });
  //     return axios.get("/api/events", { params: query }).then((response) => {
  //       return response.data.events.map((ev) => getResolvedEvent(ev));
  //     });
  //   };

  return (
    <>
      <Header className={classes.header} />
      <Box
        sx={sx.root}
        id="schedulreContainer"
        style={{ backgroundColor: !modeCtx.lightTheme && "#050D15" }}
      >
        <ThemeProvider theme={calendarTheme}>
          <Scheduler
            onConfirm={handleConfirm}
            onDelete={handleDelete}
            onEventDrop={handleOnEventDrop}
            day={null}
            //getRemoteEvents={(query) => setQuery(query)}
            getRemoteEvents={fetchRemote}
            //   events={events}
            view="month"
            month={{
              weekDays: [0, 1, 2, 3, 4, 5, 6],
              weekStartOn: 0,
              startHour: 9,
              endHour: 17,
              navigation: true,
            }}
          />
        </ThemeProvider>
      </Box>
    </>
  );
};

export default withStyles(styles)(SchedulerAdmin);
