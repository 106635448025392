import React from "react";

import { Box } from "@mui/material";
import ThemedBox from "./ThemedBox";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";
//const BP3 = "@media (max-width: 600px)";

const sx = {
  root: {
    minHeight: "100%",
    px: "125px",
    display: "flex",
    alignItems: "center",
    transition: "all .3s",
    [BP2]: {
      px: "25px",
      mb: "40px",
    },
  },
  container: {
    minHeight: "150px",
    position: "relative",
    marginX: "auto",
    marginY: "auto",
    marginBottom: "200px",
    top: "89px",
    width: "100%",
    maxWidth: "600px",
    borderRadius: "30px",
    transition: "all .3s",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    px: "30px",
    py: "20px",
    textAlign: "center",
    [BP1]: {
      top: "129px",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  header: {
    fontFamily: "Poppins-semibold",
    position: "relative",
    width: "100%",
    minHeight: "50px",
    color: "#F64C04",
  },
  body: {
    width: "100%",
    minHeight: "50px",
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  footer: {
    width: "100%",
    minHeight: "10px",
  },
};

const CardFarme = ({
  isOpen,
  setOpen,
  header,
  footer,
  children,
  isTxPopup,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Box sx={sx.root}>
      <ThemedBox sx={sx.container}>
        {header && (
          <Box sx={sx.header}>
            <h1>{header}</h1>
          </Box>
        )}
        <Box sx={sx.body}>{children}</Box>
        {footer && <Box sx={sx.footer}>{footer}</Box>}
      </ThemedBox>
    </Box>
  );
};

export default CardFarme;
