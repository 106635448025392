import React, { useContext, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { ModeCtx } from "../context/modeCtx";
import theme, { themeOptionsLight, themeOptionsDark } from "../theme";

const getTheme = (mode) => {
  return mode
    ? createTheme(theme, themeOptionsLight)
    : createTheme(theme, themeOptionsDark);
};

const ThemeBox = ({ children }) => {
  const modCtx = useContext(ModeCtx);
  const [appTheme, SetAppTheme] = useState(theme);

  useEffect(() => {
    const theme = getTheme(modCtx.lightTheme);
    SetAppTheme(theme);
  }, [modCtx.lightTheme]);

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export default ThemeBox;
