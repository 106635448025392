import { useContext } from "react";
import { Box } from "@mui/material";
import { ModeCtx } from "../../../context/modeCtx";

const sx = {
  imageHolder: {
    width: "54px",
    height: "54px",
    p: "10px",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  light: {
    border: "2px solid black",
  },
  dark: {
    border: "2px solid #F64C04",
  },
};

const ImageHolder = ({ children }) => {
  const modeCtx = useContext(ModeCtx);

  return (
    <Box
      sx={{ ...sx.imageHolder, ...(modeCtx.lightTheme ? sx.light : sx.dark) }}
    >
      {children}
    </Box>
  );
};

export default ImageHolder;
