import { useContext } from "react";
import { Box } from "@mui/material";
import { ModeCtx } from "../context/modeCtx";
import { styled } from "@mui/material/styles";

const ThemedBox = styled(Box)((theme) => {
  const modeCtx = useContext(ModeCtx);
  if (modeCtx.lightTheme) {
    return {
      backgroundColor: "transparent",
      border: "2px solid black",
    };
  }
  return {
    backgroundColor: "rgb(20, 33, 46)",
  };
});

export default ThemedBox;
