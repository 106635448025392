import React from "react";
import { Typography, Box } from "@mui/material";
import { SpinnerCircularFixed as Spinner } from "spinners-react";
import PopupModal from "./PopupModal";

const sx = {
  root: {
    width: "100%",
    height: "100%",
    background: "none",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    textAlign: "center",
    color: "#F64C04",
    lineHeight: "34px",
    fontSize: "30px",
    fontFamily: "Poppins-semibold",
  },
  url: {
    textDecoration: "none",
    color: "#F64C04",
    fontWeight: "700",
  },
};

const TxProgressModal = ({ isOpen, txEtherScan }) => {
  return (
    <PopupModal isOpen={isOpen} isTxPopup={true}>
      <Box sx={sx.root}>
        <Typography variant="heading" sx={sx.title}>
          Transaction in progress
        </Typography>
        <Typography variant="text" sx={{ my: 4 }}>
          Please wait while your transaction is being processed. <br />
          {txEtherScan && (
            <>
              You can check the transaction status on{" "}
              <Box
                component="a"
                href={txEtherScan}
                sx={sx.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Etherscan
              </Box>
              .
            </>
          )}
        </Typography>

        <Spinner
          color="#F64C04"
          size={64}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />
      </Box>
    </PopupModal>
  );
};

export default TxProgressModal;
