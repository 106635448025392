//toCommon
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Button, Collapse, Modal } from "@mui/material";
import { useLocation } from "react-router-dom";
import galaxisLogo from "../assets/images/galaxis.svg";
import galaxisLogoWhite from "../assets/images/Galaxis_black.svg";
import HeaderButton from "./HeaderButton";
import collapsedIcon from "../assets/images/collapse_icon.svg";
import collapsedIconBlack from "../assets/images/collapse_icon_black.svg";
import menuIcon from "../assets/images/menu_icon.svg";
import menuIconForBlack from "../assets/images/menu_icon_for_black.svg";
import email from "../assets/images/social/email.svg";
import emailForBlack from "../assets/images/social/emailForBlackTheme.svg";
import EmailPopup from "./EmailPopup";
import { ModeCtx } from "../context/modeCtx";
import sun from "../assets/images/sun.svg";
import moon from "../assets/images/moon.svg";
import ConfigContext from "../context/ConfigContext";

import { createBrowserHistory } from "history";
const history = createBrowserHistory({ window });

const HOME_URL = "https://galaxis.xyz/#/";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 401px)";

const sx = {
  root: {
    width: "100%",
    height: "84px",
    position: "fixed",
    zIndex: 1001,
    px: "20px",
    [BP1]: {
      display: "none",
    },
  },
  content: {
    width: "100%",
    maxWidth: "1140px",
    height: "100%",
    mx: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoContainer: {
    width: "100%",
    maxWidth: "180px",
    cursor: "pointer",
    zIndex: "21",
    "& img": {
      width: "100%",
    },
    [BP2]: {
      maxWidth: "130px",
    },
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
  },
  btn: {
    fontFamily: "poppins",
    fontSize: "20px",
    color: "#FFF",
  },
  mobileContainer: {
    display: "none",
    [BP1]: {
      display: "block",
      gap: "30px",
    },
  },
  headerRoot: {
    // transition: '3s ease-out',
    position: "fixed",
    width: "100%",
    pl: "20px",
    zIndex: "1001",
    maxWidth: "1272px",
    height: "124px",
    mx: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collapsedMenu: {
    position: "fixed",
    zIndex: "2222",
    width: "100%",
    mt: "100px",
    pt: "10px",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    backgroundColor: "#FAF9F5",
    px: "20px",
    paddingBottom: "25px",
  },
  menuContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "9px 0",
    zIndex: "1",
  },
  menuList: {
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    width: "155px",
    height: "168px",
    position: "absolute",
    fontSize: "16px",
    boxShadow: "2px 2px 2px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "6px",
    pt: "10px",
    pb: "0",
  },
  socialContainer: {
    maxHeight: "30px",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    px: "5px",
    pt: "8px",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: "10px",
    zIndex: "2223",
  },
  ulBox: {
    mt: "36px",
    borderRadius: "10px",
  },
  rootHorizontal: {
    height: "45px",
    px: "6.5px",
    pt: "10px",
    textTransform: "uppercase",
    [BP1]: {
      px: "5px",
    },
    "& ul": {
      display: "none",
      position: "absolute",
      margin: "0",
      padding: "0",
    },
    "& li": {
      listStyle: "none",
      height: "30px",
      "&:hover": {
        "& span": {
          color: "#F64C04!important",
          fontWeight: 700,
        },
      },
      "& div": {
        padding: "10px",
      },
    },
    "&:hover": {
      "& ul": {
        display: "block",
      },
    },
  },
  text: {
    "&:disabled": {
      color: "#F64C04",
    },
    fontFamily: "poppins",
    fontSize: "18px",
    color: "#111317",
    textAlign: "right",
    lineHeight: "21px",
    "&:hover": {
      color: "#F64C04!important",
      fontWeight: "700",
    },
  },
  rootDisabled: {
    color: "#F64C04",
    fontWeight: 700,
  },
  disconnectButton: {
    minHeight: "40px",
    height: "40px",
    width: "120px",
    borderRadius: "5px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    backgroundColor: "unset",
    border: "2px solid #F64C04",
    color: "#F64C04",
    marginTop: "0",
    marginLeft: "5px",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#ffffff",
    },
  },
};

const Header = ({ clickedToTheSameLink, address, disconnect }) => {
  const config = useContext(ConfigContext);
  const BUTTONS = ["Home"];
  const PATHS = [HOME_URL];

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modeCtx = useContext(ModeCtx);

  const disableMoon = useMemo(() => {
    return window.location.href.indexOf("scheduler") > 0;
  });

  const handleNavigation = (path) => {
    setMenuOpened(false);
    if (PATHS[path] === "/" + location.hash) {
      clickedToTheSameLink();
    }
    //  console.log(PATHS[path], location.hash);
    if (location.pathname !== path) {
      history.push(path);
      history.push(PATHS[path]);
    }
  };

  const handleMouseOver = (path) => {
    setActiveTab(path);
  };

  const handleMouseLeave = () => {
    if (location.pathname === "/") {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  }, [location.pathname]);

  const handleNavigationLogo = (path) => {
    setActiveTab(null);
    setMenuOpened(false);
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
    if (location.pathname !== path) {
      history.push(path);
      history.push(PATHS[path]);
    }
  };

  const handleThemeChange = () => {
    modeCtx.setLightTheme();
    setMenuOpened(false);
  };

  return (
    <>
      <Box
        sx={sx.root}
        style={{
          backgroundColor: modeCtx.lightTheme ? "#faf9f5ba" : "#050d15ab",
        }}
      >
        <Box sx={sx.content}>
          <Box
            sx={sx.logoContainer}
            style={{ cursor: "pointer" }}
            onClick={() => handleNavigationLogo("/")}
          >
            {modeCtx.lightTheme ? (
              <img src={galaxisLogo} alt="Ether cards" />
            ) : (
              <img src={galaxisLogoWhite} alt="Ether cards" />
            )}
          </Box>
          <Box sx={sx.btnContainer}>
            <Box sx={sx.rootHorizontal} style={{ cursor: "pointer" }}></Box>
            {BUTTONS.map((btn, i) => {
              return (
                <HeaderButton
                  key={btn}
                  active={activeTab === i}
                  text={btn}
                  onClick={() => handleNavigation(i)}
                  onMouseOver={() => handleMouseOver(i)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })}

            {!disableMoon ? (
              <div
                style={{
                  height: "45px",
                  cursor: "pointer",
                  paddingLeft: "6.5px",
                  paddingRight: "6.5px",
                  paddingTop: "10px",
                }}
              >
                <span>
                  {modeCtx.lightTheme ? (
                    <img
                      src={moon}
                      alt="moon"
                      className="sun"
                      style={{ height: "21px" }}
                      onClick={handleThemeChange}
                    />
                  ) : (
                    <img
                      src={sun}
                      alt="sun"
                      className="sun"
                      style={{ height: "21px" }}
                      onClick={() => handleThemeChange()}
                    />
                  )}
                </span>
              </div>
            ) : null}
            <Box sx={sx.socialContainer}>
              <Box
                onClick={() => setModalIsOpen(true)}
                style={{ cursor: "pointer" }}
              >
                {modeCtx.lightTheme ? (
                  <img
                    src={email}
                    style={{ width: "100%", maxHeight: "24px" }}
                    alt="not found"
                  />
                ) : (
                  <img
                    src={emailForBlack}
                    style={{ width: "100%", maxHeight: "24px" }}
                    alt="not found"
                  />
                )}
              </Box>
            </Box>

            {address && disconnect && (
              <Button
                variant="banner"
                sx={sx.disconnectButton}
                onClick={() => disconnect()}
              >
                Disconnect
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={sx.mobileContainer}>
        <Box
          sx={sx.headerRoot}
          style={{
            backgroundColor: modeCtx.lightTheme ? "#faf9f5" : "#050D15",
          }}
        >
          <Box
            sx={sx.logoContainer}
            style={{ marginTop: "6px" }}
            onClick={() => handleNavigationLogo("/")}
          >
            {modeCtx.lightTheme ? (
              <img src={galaxisLogo} alt="Ether cards" />
            ) : (
              <img src={galaxisLogoWhite} alt="Ether cards" />
            )}
          </Box>
          <Box
            sx={sx.menuContainer}
            onClick={() => setMenuOpened((state) => !state)}
          >
            <Button sx={sx.menubtn}>
              {modeCtx.lightTheme ? (
                <img
                  style={{ width: "100%", paddingRight: "10px" }}
                  src={menuOpened ? collapsedIcon : menuIconForBlack}
                  alt="Menu icon"
                />
              ) : (
                <img
                  style={{ width: "100%", paddingRight: "10px" }}
                  src={menuOpened ? collapsedIconBlack : menuIcon}
                  alt="Menu icon"
                />
              )}
            </Button>
          </Box>
        </Box>
        <Collapse in={menuOpened} timeout="auto" unmountOnExit>
          <Box
            sx={sx.collapsedMenu}
            style={{
              backgroundColor: modeCtx.lightTheme ? "#faf9f5" : "#050D15",
            }}
          >
            {BUTTONS.map((btn, i) => {
              return (
                <HeaderButton
                  key={btn}
                  active={activeTab === i}
                  text={btn}
                  onClick={() => handleNavigation(i)}
                  onMouseOver={() => handleMouseOver(i)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })}
            <div
              style={{
                height: "45px",
                cursor: "pointer",
                paddingLeft: "6.5px",
                paddingRight: "6.5px",
                paddingTop: "10px",
              }}
            >
              <span>
                {modeCtx.lightTheme ? (
                  <img
                    src={moon}
                    alt="moon"
                    className="sun"
                    style={{ height: "21px" }}
                    onClick={handleThemeChange}
                  />
                ) : (
                  <img
                    src={sun}
                    alt="sun"
                    className="sun"
                    style={{ height: "21px" }}
                    onClick={handleThemeChange}
                  />
                )}
              </span>
            </div>
            <Box sx={sx.socialContainer}>
              <Box
                onClick={() => {
                  setModalIsOpen(true);
                  setMenuOpened(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {modeCtx.lightTheme ? (
                  <img
                    src={email}
                    style={{ width: "100%", maxHeight: "24px" }}
                    alt="not found"
                  />
                ) : (
                  <img
                    src={emailForBlack}
                    style={{ width: "100%", maxHeight: "24px" }}
                    alt="not found"
                  />
                )}
              </Box>
            </Box>
            {address && disconnect && (
              <Button
                variant="banner"
                sx={sx.disconnectButton}
                onClick={() => disconnect()}
              >
                Disconnect
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>

      <Modal
        onBackdropClick={() => {
          setModalIsOpen(false);
        }}
        sx={sx.modal}
        open={modalIsOpen}
      >
        <EmailPopup
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default Header;
