import React from "react";
import { theme } from "galaxis-utraits-client-common";
import { ThemeProvider } from "@mui/material/styles";
//import Async from "react-async";
//import { askSignature } from "../../services/askSignature";
//import AdminContext from "../../context/AdminContext";
//import { SpinnerCircular } from "spinners-react";
//import { Box, Typography } from "@mui/material";

import AdminTable from "./components/AdminTable";

// const sx = {
//   root: {
//     minHeght: "100vh",
//     height: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundColor: "#050D15",
//     px: "20px",
//   },
//   title: {
//     textAlign: "center",
//     color: "#F64C04",
//     lineHeight: "34px",
//     fontSize: "30px",
//   },
// };

const Admin = () => {
  //   const loadingSpinner = () => {
  //     return (
  //       <>
  //         <Box sx={sx.root}>
  //           <Typography sx={sx.title} style={{ color: "white" }}>
  //             Please sign your request!
  //           </Typography>
  //           <SpinnerCircular
  //             size={60}
  //             thickness={145}
  //             speed={100}
  //             color="#F64C04"
  //             secondaryColor="rgba(172, 109, 57, 0.44)"
  //             enabled={true}
  //           />
  //         </Box>
  //       </>
  //     );
  //   };

  return (
    // <Async promiseFn={askSignature}>
    //   <Async.Loading>{loadingSpinner()}</Async.Loading>
    //   <Async.Resolved>
    //     {(signatureData) => (
    //       <AdminContext.Provider value={signatureData}>
    <ThemeProvider theme={theme}>
      <AdminTable />
    </ThemeProvider>
    //       </AdminContext.Provider>
    //     )}
    //   </Async.Resolved>
    //   <Async.Rejected>
    //     {(error) => `Something went wrong: ${error.message}`}
    //   </Async.Rejected>
    // </Async>
  );
};

export default Admin;
