import "./App.css";
import React from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toast";
import Home from "./View/Home/Home";
import Admin from "./View/Admin/Admin";
import SchedulerAdmin from "./View/SchedulerAdmin/SchedulerAdmin";
import NotFound from "./components/NotFound/NotFound";
import ModeCtxProvider from "./context/modeCtx";
import ThemeBox from "./components/ThemeBox";
import Async from "react-async";
import initConfig from "./services/config";
import ConfigContext from "./context/ConfigContext";
import CssBaseline from "@mui/material/CssBaseline";
import { Web3OnboardProvider } from "@web3-onboard/react";

import initOnboard from "./services/onboard";

const sx = {
  root: {
    height: "100%",
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
};

const App = () => {
  return (
    <Async promiseFn={initConfig}>
      <Async.Loading>Loading...</Async.Loading>
      <Async.Resolved>
        {(config) => {
          const web3Onboard = initOnboard();
          return (
            <ConfigContext.Provider value={config}>
              <Web3OnboardProvider web3Onboard={web3Onboard}>
                <ModeCtxProvider>
                  <React.StrictMode>
                    <ThemeBox>
                      <CssBaseline />
                      <Box sx={sx.root}>
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="admin" element={<Admin />} />
                          <Route
                            path="scheduler"
                            element={<SchedulerAdmin />}
                          />
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                        <ToastContainer delay={8000} position="top-left" />
                      </Box>
                    </ThemeBox>
                  </React.StrictMode>
                </ModeCtxProvider>
              </Web3OnboardProvider>
            </ConfigContext.Provider>
          );
        }}
      </Async.Resolved>
      <Async.Rejected>
        {(error) => `Something went wrong: ${error.message}`}
      </Async.Rejected>
    </Async>
  );
};

export default App;
