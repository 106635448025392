//import {askSimpleSignature, hashMessage, personalSign} from "grd-client-commons";
import {
  askSimpleSignature,
  hashMessage,
  personalSign,
} from "galaxis-utraits-client-common";

const buildFormMessage = (formData) => {
  const { tokenId, traitId, calendarEventId, startDate, to } = formData;
  const signedOn = new Date().toISOString();
  //const message = JSON.stringify(formData);
  const message = `${tokenId},${traitId},${calendarEventId},${startDate},${to},${signedOn}`;
  return { message, signedOn };
};

const signFormData = async (formData) => {
  // ---- Build, hash and sign message ----
  const { message, signedOn } = buildFormMessage(formData);
  const messageHash = hashMessage(message);
  const { signature, signerAddress } = await personalSign(messageHash);
  if (!signature) {
    return false;
  }

  // Test: Recover the address from signature
  // const recoveredAddress = ethers.utils.verifyMessage(messageHash, signature);
  // console.log('recoveredAddress:', recoveredAddress);

  return { signature, messageHash, signerAddress, signedOn, ...formData };
};

export { askSimpleSignature, signFormData };
