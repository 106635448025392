import React from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    gap: "20px",
    px: "70px",
    mb: "30px",
  },
  modalButton: {
    border: "2px solid #F64C04",
    backgroundColor: "unset",
    color: "#F64C04",
    margin: "0px",
    boxShadow: "unset",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
    "&:active": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
  },
  buttonHolder: {
    display: "flex",
    gap: "20px",
    margin: "0px",
    maxWidth: "320px",
    width: "100%",
    justifyContent: "flex-end",
  },
};

const DisplayRestart = ({ handleRestart, displayWhen }) => {
  if (!displayWhen) {
    return null;
  } else {
    return (
      <Box sx={sx.root}>
        {/* <Box sx={sx.buttonHolder}> */}
        <Button
          onClick={handleRestart}
          type="button"
          sx={sx.modalButton}
          variant="contained"
        >
          Restart transaction
        </Button>
        {/* </Box> */}
      </Box>
    );
  }
};

export default DisplayRestart;
