import { useMemo } from "react";
import ContractService from "../services/ContractService";

const useContractService = (traitId) => {
  const getContractService = (traitId) => {
    return new ContractService(traitId);
  };

  const contractService = useMemo(() => getContractService(traitId), [traitId]);

  return contractService;
};

export default useContractService;
