import React, { useEffect, useState, useMemo, useCallback } from "react";
import { default as axios } from "../../../api";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Paper, createTheme, ThemeProvider } from "@mui/material";
import useModCtx from "../../../hooks/useModCtx";
import useToken from "../../../hooks/useToken";

import { toast } from "react-toast";
import Link from "@mui/material/Link";
import { DataGridStyled } from "galaxis-utraits-client-common";
import sx from "./sx";
import columns from "./columns";
import ThemeBox from "../../../components/ThemeBox";

const AdminTable = () => {
  const modeCtx = useModCtx();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = useState(null);
  const [filterModel, setFilterModel] = useState(null);

  const [pageInfo, setPageInfo] = useState({
    totalRowCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const token = useToken();

  const queryOptions = useMemo(
    () => ({
      page,
      pageSize,
      sortModel,
      filterModel,
    }),
    [filterModel, page, pageSize, sortModel]
  );

  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.totalRowCount || 0
  );

  useEffect(() => {
    if (!modeCtx.lightTheme) {
      modeCtx.setLightTheme();
    }
  }, [modeCtx]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/contacts", {
        params: queryOptions,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPageInfo(response.data.pageInfo);
        setTableData(response.data.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  }, [queryOptions, token]);

  const handleSortModelChange = useCallback((sortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleFilterChange = (filterModel) => {
    setFilterModel(filterModel);
  };

  function QuickSearchToolbar() {
    const theme = createTheme({
      components: {
        // Name of the component
        MuiInputBase: {
          styleOverrides: {
            root: {
              "& .MuiSvgIcon-root": {
                color: modeCtx.lightTheme ? "black" : "white",
              },
            },
            input: {
              color: modeCtx.lightTheme ? "black" : "white",
              backgroundColor: modeCtx.lightTheme ? "#faf9f5ba" : "#050d15ab",
            },
          },
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            backgroundColor: modeCtx.lightTheme ? "white" : "#14212e",
          }}
        >
          <GridToolbarQuickFilter />
        </Box>
      </ThemeProvider>
    );
  }
  return (
    <ThemeBox>
      <div style={{ height: "100vh", width: "100%" }}>
        {/* <div style={{ display: "flex", height: "100%", padding: "100px" }}> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "100px",
          }}
        >
          <Box style={{ marginBottom: "10px", marginTop: "10px" }}>
            <Link href="/scheduler" target="_blank" sx={sx.calendarLink}>
              OPEN CALENDAR
            </Link>
          </Box>
          <Box
            style={{ flexGrow: 1, backgroundColor: "white" }}
            component={Paper}
            elevation={10}
          >
            <DataGridStyled
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              sx={{ padding: 1 }}
              experimentalFeatures={{ newEditingApi: true }}
              components={{
                Toolbar: QuickSearchToolbar,
              }}
              rows={tableData}
              columns={columns}
              loading={isLoading}
              rowCount={rowCountState}
              rowsPerPageOptions={[5, 10, 20, 50]}
              pageSize={pageSize}
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterChange}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </Box>
      </div>
    </ThemeBox>
  );
};

export default AdminTable;
