import { Typography, Box } from "@mui/material";
import React from "react";
import { SpinnerCircular } from "spinners-react";

const sx = {
  description: {
    margin: "auto",
    maxWidth: "600px",
    fontFamily: "poppins",
    fontSize: "16px",
    //color: "#D2D4C2",
    textAlign: "center",
    lineHeight: "1.8",
  },
};

const DisplayBox = ({ message, displayWhen, showSpinner = false }) => {
  if (!displayWhen || !message) {
    return null;
  } else {
    return (
      <Box sx={sx.description}>
        <SpinnerCircular
          size={60}
          thickness={145}
          speed={100}
          color="#F64C04"
          secondaryColor="rgba(172, 109, 57, 0.44)"
          enabled={showSpinner}
        />
        <Typography
          sx={sx.description}
          style={{
            paddingBlock: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontFamily: "Poppins",
            position: "relative",
            fontSize: "24px",
          }}
        >
          {message}
        </Typography>
      </Box>
    );
  }
};

export default DisplayBox;
