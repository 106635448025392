import { ethers } from "ethers";
import { getContract, contracts } from "../utils/contract-util.js";
import { toast } from "react-toast";

const STORAGE_INDEX = "AppointmentStorage";
const STORAGE_CONTROLLER = "AppointmentController";
const GALAXIS_REGISTRY_ADDRESS = "0x1e8150050A7a4715aad42b905C08df76883f396F";

export default class ContractService {
  chainTraits;
  controllerContract;
  storageContract;
  provider;
  traitId;
  metaDataUrl;
  NFTToolbox;
  initialized = false;

  traitName = () => {
    try {
      return this.chainTraits[this.traitId].name;
    } catch (error) {
      console.error(error);
    }
  };

  traitType = () => {
    console.log(this.chainTraits);
    try {
      return this.chainTraits[this.traitId].traitType;
    } catch (error) {
      console.error(error);
      throw Error("Invalid trait");
    }
  };

  async init(traitId) {
    if (!this.NFTToolbox) {
      this.initialized = false;
    }
    if (!this.ECRegistryV3) {
      this.initialized = false;
    }
    if (this.initialized) return;
    this.traitId = +traitId;
    const config = JSON.parse(localStorage.getItem("appConfig"));
    const RPC_URL = config.RPC_URL;
    const COMMUNITY_ID = config.COMMUNITY_ID;

    // this.GalaxisRegistry = await this.galaxisRegistry();
    // console.log("GalaxisRegistry", this.GalaxisRegistry);

    // this.CommunityListAddress = this.communityListAddress();
    // console.log("CommunityListAddress", this.communityListAddress);

    // this.CommunityList = await getContract(
    //   "CommunityList",
    //   this.CommunityListAddress
    // );
    // console.log("CommunityList", this.CommunityList);

    // this.Community = await this.CommunityList.communities(COMMUNITY_ID);
    // console.log("Community", this.Community);

    // this.CommunityRegistry = await getContract(
    //   "CommunityRegistry",
    //   this.Community.registry
    // );
    // console.log("CommunityRegistry", this.CommunityRegistry);

    // this.TokenAddress = await this.CommunityRegistry.getRegistryAddress(
    //   "TOKEN_1"
    // );
    // console.log("TokenAddress", this.TokenAddress);

    //this.NFTToolbox = await getContract("NFTToolbox", this.TokenAddress);
    this.NFTToolbox = await getContract("NFTToolbox", config.NFTToolbox);
    console.log("NFTToolbox", this.NFTToolbox);

    this.metaDataUrl = await this.NFTToolbox.tokenRevealURI();

    // this.RegistryAddress = await this.CommunityRegistry.getRegistryAddress(
    //   "TRAIT_REGISTRY_1"
    // );
    // console.log("RegistryAddress", this.RegistryAddress);

    //this.ECRegistryV3 = await getContract("ECRegistryV3", this.RegistryAddress);
    this.ECRegistryV3 = await getContract("ECRegistryV3", config.ECRegistryV3);
    console.log("ECRegistryV3", this.ECRegistryV3);

    //console.log("RPC_URL", RPC_URL);
    try {
      this.provider = new ethers.providers.JsonRpcProvider(RPC_URL);
      //this.provider = new ethers.providers.Web3Provider(window.ethereum);
    } catch (error) {
      console.error(error);
    }
    //this.provider = new ethers.providers.Web3Provider(window.ethereum);

    if (!this.provider) {
      console.error({
        message: "No provider found",
        RPC_URL: RPC_URL,
      });
    }

    this.chainTraits = await this.getTraits();
    //console.log("chainTraits", this.chainTraits);

    if (!this.chainTraits.length) {
      console.error({
        message: "No chain traits found",
        ECRegistryV3: this.ECRegistryV3,
      });
    }

    this.controllerContract = await this.getControllerContract(+traitId);

    //console.log("controllerContract", this.controllerContract);

    if (!this.controllerContract) {
      console.error({
        message: "No provider found",
        RPC_URL: RPC_URL,
      });
    }

    this.storageContract = await this.getStorageContract(+traitId);
    //console.log("storageContract", this.storageContract);

    if (!this.storageContract) {
      console.error({
        message: "No provider found",
        RPC_URL: RPC_URL,
      });
    }

    if (!this.chainTraits.length) {
      console.error("no traits found");
    }

    //console.log("init done");
    this.initialized = true;
  }

  tokenUri = async (tokenId) => {
    if (!this.NFTToolbox) return;
    return await this.NFTToolbox.tokenURI(tokenId);
  };

  burn = async (tokenId, calendarEventIdHash) => {
    const REDEEMED_BY_USER = 2;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const tx = await this.controllerContract
      .connect(signer)
      .setValue(
        [tokenId],
        REDEEMED_BY_USER,
        [calendarEventIdHash],
        this.chainTraits[this.traitId].storageImplementer
      );

    toast.success("Transaction was successfully sent - Trait will be Burned");
    return tx;
  };

  getTokens = async (address) => {
    try {
      const numberOfTokens = await this.NFTToolbox.balanceOf(address);
      if (numberOfTokens.toNumber() > 0) {
        const promises = [];
        const tokenIds = [];
        for (let i = 0; i < numberOfTokens; i++) {
          promises.push(
            this.NFTToolbox.tokenOfOwnerByIndex(address, i).catch((err) =>
              console.error(`Error getting ${i}. token of address ${address}`)
            )
          );
        }
        await Promise.all(promises).then((tokens) =>
          tokens.map((token) => tokenIds.push(Number(token)))
        );
        tokenIds.sort((a, b) => {
          if (Number(a) < Number(b)) {
            return -1;
          }
          if (Number(a) > Number(b)) {
            return 1;
          }
          return 0;
        });
        return tokenIds;
      }
    } catch (error) {
      console.warn("NFT contract could not be accessed!");
    }
  };

  getTraits = async () => {
    try {
      const chainTraits = await this.ECRegistryV3.getTraits();
      // console.log("Available traits:");
      // chainTraits.map((trait) => { console.log(`   TraitID: ${trait.id}  Type: ${trait.traitType}  Storage: ${trait.storageImplementer} Name: ${trait.name}`); });
      return chainTraits;
    } catch (error) {
      toast.error("Registry contract could not be accessed!");
    }
  };

  isValidTrait = (traitId) => {
    return this.chainTraits.length >= traitId;
  };

  storageImplementer = () => {
    return this.chainTraits[this.traitId].storageImplementer;
  };

  abi = (index) => {
    return contracts[index].abi;
  };

  getStorageContract = async (traitId) => {
    // TraitIds are starting from 1, so index of trait in the array is offset by -1 !!!
    return new ethers.Contract(
      this.storageImplementer(),
      this.abi(STORAGE_INDEX),
      this.provider
    );
  };

  controllerAddress = async () => {
    return await this.ECRegistryV3.getDefaultTraitControllerByType(
      this.traitType()
    );
  };

  getControllerContract = async (traitId) => {
    return new ethers.Contract(
      await this.controllerAddress(),
      this.abi(STORAGE_CONTROLLER),
      this.provider
    );
  };

  getTraitValue = async (tokenId) => {
    if (!this.storageContract) {
      console.error("storageContract is null");
      return;
    }
    // console.log('storage:', this.storageContract, "tokenId:", tokenId);
    return await this.storageContract.getValue(+tokenId);
  };
}
