import { useEffect, useState } from "react";

const useIsWrongQueryParams = (tokenId, traitId) => {
  const [isWrongQueryParams, setIsWrongQueryParams] = useState(true);

  useEffect(() => {
    if (traitId === null || tokenId === null) {
      setIsWrongQueryParams(true);
    } else {
      setIsWrongQueryParams(false);
    }
  }, [traitId, tokenId]);

  return isWrongQueryParams;
};

export default useIsWrongQueryParams;
