import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useToken = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getAccessToken = (query) => {
    return query?.get("token");
  };

  const token = useMemo(() => getAccessToken(query), [query]);

  return token;
};

export default useToken;
