import { Button } from "@mui/material";
import React from "react";

const sx = {
  modalButton: {
    border: "2px solid #F64C04",
    backgroundColor: "unset",
    color: "#F64C04",
    margin: "0px",
    boxShadow: "unset",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
    "&:active": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
  },
};

const RestartTransactionButton = () => {
  return (
    <Button type="submit" sx={sx.modalButton} variant="contained">
      RESTART TRANSACTION
    </Button>
  );
};

export default RestartTransactionButton;
