import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const BP1 = "@media (max-width: 600px)";
const COLORS = {
  primaryColor: "#F64C04",
  secondaryColor: "#f50057",
  blue2: "#00A5F7",
  blue3: "#7FC4FD",
  blue4: "#BCE0FD",
  grey: "#F1F9FF",
  light: "#FFF",
  black: "#000000",
  orange: "#F64C04",
};

export const themeOptionsLight = {
  palette: {
    mode: "light",
    primary: {
      //main: COLORS.black,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ul: {
          color: "transparent",
        },
        li: {
          backgroundColor: "#FFFFFF",
          color: "black",
          "&:hover": {
            background: `linear-gradient(${grey[100]}, ${grey[300]})`,
            color: "#F64C04",
          },
          '&[aria-selected="true"]': {
            color: "#F64C04",
            background: `linear-gradient(${grey[100]}, ${grey[300]})`,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          label: {
            color: "grey",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "2px solid #000000",
              borderColor: "#000000",
              borderRadius: "6px",
            },
            "&:hover fieldset": {
              borderColor: "#000000",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#F64C04",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            color: COLORS.black,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiButton-text": {
            color: "#F64C04",
          },
        },
      },
    },
  },
};

export const themeOptionsDark = {
  palette: {
    mode: "dark",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ul: {
          color: "transparent",
        },
        li: {
          backgroundColor: "rgb(20, 33, 46)",
          color: "white",
          "&:hover": {
            background: "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))",
          },
          '&[aria-selected="true"]': {
            color: "#F64C04",
            background: "linear-gradient(rgb(20, 33, 46), rgb(14, 25, 36))",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          label: {
            color: "#fff9",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "2px solid #F64C04",
              borderColor: "#F64C04",
              borderRadius: "6px",
            },
            "&:hover fieldset": {
              borderColor: "#F64C04",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#F64C04",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& fieldset": {
            border: "2px solid #F64C04",
          },
        },
        input: {
          color: COLORS.light,
          backgroundColor: "transparent",
          borderColor: "#F64C04!important",
          "& label": {
            color: "#fff9",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: "#F64C04",
          },
        },
      },
    },
  },
};

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "#030000",
    bannerTitle: {
      fontFamily: "poppins-semibold",
      fontSize: "36px",
      lineHeight: "50px",
      width: "100%",
      zIndex: 10,
      transition: "all .3s",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      [BP1]: {
        fontSize: "26px",
        lineHeight: "36px",
      },
    },
    bannerText: {
      fontSize: "16px",
      lineHeight: "24px",
      width: "100%",
      zIndex: 10,
      fontFamily: "Poppins",
      transition: "all .3s",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
    },
    projectSubtitle: {
      fontFamily: "poppins-semibold",
      textTransform: "unset",
      color: "#000",
      fontSize: "36px",
      lineHeight: "50px",
      width: "100%",
      zIndex: 10,
      transition: "all .3s",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
    },
    bannerCubeText: {
      fontFamily: "Poppins",
      color: "#FAF9F6",
      fontSize: "40px",
      lineHeight: "40px",
      width: "100%",
      zIndex: 10,
      transition: "all .3s",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
    },
    footerText: {
      color: "#FFFFFF",
      opacity: "60%",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
    footerTitle: {
      color: "#FAF4F2",
      fontFamily: "Poppins",
      fontSize: "22px",
      marginBottom: "24px",
    },
    minuscule: {
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "5,06px",
      color: "#1C1D22",
    },
    heading: {
      fontFamily: "poppins-semibold",
      fontSize: "26px",
      lineHeight: "22px",
      color: "#1C1D22",
    },
  },
  palette: {
    primary: {
      main: COLORS.primaryColor,
    },
    background: {
      socialIcon: COLORS.blue2,
      grey: COLORS.grey,
      infoBox: COLORS.blue3,
      image: COLORS.blue4,
      light: COLORS.light,
    },
    light: {
      main: "#FFF",
    },
    text: {
      primary: "#FFF",
    },
    error: {
      main: "#EE1B11",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          "&.Mui-focused": {
            visibility: "visible",
          },
          "&.MuiFormLabel-filled": {
            visibility: "hidden",
          },
          "&.MuiFormLabel-filled + div legend": {
            display: "none",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: COLORS.blue1,
          fontFamily: "Poppins",
          fontSize: "18px",
        },
      },
      defaultProps: {
        variantMapping: {
          body1: "div",
          bannerTitle: "div",
          bannerText: "div",
          heading1: "div",
          heading2: "div",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontFamily: "poppins-semibold",
          fontSize: "16px",
          border: "2px solid #F64C04",
          [BP1]: {
            mt: "15px",
          },
          "&:hover": {
            border: "2px solid #F64C04",
            backgroundColor: "#F64C04",
            color: "white",
          },
        },
        contained: {
          color: "#FFF",
          height: "48px",
          fontSize: "16px",
          fontWeight: 600,
        },
        banner: {
          // minHeight: '55px',
          fontFamily: "Poppins",
          fontWeight: 500,
          borderRadius: 0,
          fontSize: "14px",
          color: "#FFF",
          padding: "10px 20px",
          lineHeight: "20px",
          backgroundColor: "#F64C04",
          "&:hover": {
            backgroundColor: "#F64C04",
          },
        },
        collectionCard: {
          borderRadius: "5px",
          minHeight: "54px",
          fontFamily: "poppins-semibold",
          fontSize: "14px",
          color: "#F64C04",
          border: "2px solid #F64C04",
          "&:hover": {
            border: "2px solid #F64C04",
            backgroundColor: "#F64C04",
            color: "#FFF",
          },
        },
      },
    },
  },
});

export default theme;
