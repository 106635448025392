const BP1 = "@media (max-width: 500px)";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    gap: "10px",
    //px: "70px",
    mb: "30px",
    overflowY: "auto",
    width: "100%",
    maxWidth: "450px",
    margin: "auto",
  },
  rootHolder: {
    margin: "20px",
    maxWidth: "1280px",
    display: "flex",
    //flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#152D2E",
  },
  header: {
    display: "flex",
    mt: "45px",
  },
  imageHolder: {
    width: "54px",
    height: "54px",
    border: "2px solid black",
    p: "10px",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: "416px",
    color: "white",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "38px",
    textAlign: "center",
    lineHeight: "40px",
    color: "#F64C04",
    mt: "20px",
    mb: "20px",
    fontWeight: "bold",
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "18px",
    textAlign: "center",
    color: "black",
  },
  bookHolder: {
    display: "flex",
    row: 2,
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
    BP2: {
      justifyContent: "row",
    },
    backgroundColor: "white",
  },
  date: {
    maxWidth: "190px",
    minWidth: "190px",
    maxHeight: "51px",
    minHeight: "51px",
    border: "1px solid black",
    borderRadius: "6px",
    transition: "0.5s",
    [BP1]: {
      maxWidth: "100%",
      minWidth: "165px",
      maxHeight: "51px",
      minHeight: "51px",
    },
  },
  dateText: {
    fontFamily: "Poppins",
    fontSize: "12px",
    textAlign: "center",
    py: "17px",
    px: "10px",
  },
  buttonHolder: {
    display: "flex",
    gap: "20px",
    margin: "0px",
    mt: "20px",
    maxWidth: "320px",
    width: "100%",
    justifyContent: "center",
  },
  selectedDate: {
    color: "var(--orangePrimary)",
    fontWeight: "bold",
  },
  emailBox: {
    padding: "20px",
    width: "100%",
    margin: "auto",
    justifyContent: "center",
  },
  grid: {
    mt: "20px",
  },
  dateHolder: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "auto",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
    overflowY: "auto",
    padding: "5px",
    [BP1]: {
      flexDirection: "column",
    },
  },
};

export default sx;
