import React from "react";

import { Box, Modal } from "@mui/material";
import ThemedBox from "./ThemedBox";
import close from "../assets/images/close.svg";
import useModCtx from "../hooks/useModCtx";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";

const sx = {
  root: {
    height: "100%",
    //backgroundColor: "rgba(0,0,0,0.3)",
    py: "44px",
    pl: "90px",
    pr: "106px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    // pointerEvents: "none",
    transition: "all .3s",
    [BP1]: {
      pl: "70px",
      pr: "87px",
    },
    [BP2]: {
      pl: "25px",
      pr: "42px",
    },
  },
  container: {
    //maxHeight: "80vh",
    minHeight: "150px",
    position: "relative",
    margin: "auto",
    width: "100%",
    maxWidth: "500px",
    borderRadius: "16px",
    border: "1px solid #050000",
    overflow: "hidden",
    transition: "all .3s",
    //backgroundColor: "#D2D4C2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    px: "30px",
    py: "20px",
    textAlign: "center",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  closeBtn: {
    position: "absolute",
    width: "14px",
    height: "14px",
    top: "38%",
    bottom: "0",
    right: "5px",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
    "&:hover": {
      opacity: 0.8,
    },
  },
  header: {
    fontFamily: "Poppins-semibold",
    position: "relative",
    width: "100%",
    minHeight: "50px",
    color: "#F64C04",
  },
  body: {
    width: "100%",
    minHeight: "50px",
    fontFamily: "poppins",
    fontSize: "16px",
  },
  footer: {
    width: "100%",
    minHeight: "10px",
  },
};

const PopupModal = ({
  isOpen,
  setOpen,
  header,
  footer,
  children,
  isTxPopup,
}) => {
  const modeCtx = useModCtx();
  sx.container.backgroundColor = modeCtx.lightTheme ? "white" : "none";
  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          if (setOpen) {
            setOpen(false);
          }
        }
      }}
    >
      <Box sx={sx.root}>
        <ThemedBox sx={sx.container}>
          <Box sx={sx.header}>
            {!isTxPopup && (
              <Box sx={sx.closeBtn} onClick={() => setOpen && setOpen(false)}>
                <img src={close} style={{ width: "100%" }} alt="Close" />
              </Box>
            )}

            <h1>{header}</h1>
          </Box>
          <Box sx={sx.body}>{children}</Box>
          <Box sx={sx.footer}>{footer}</Box>
        </ThemedBox>
      </Box>
    </Modal>
  );
};

export default PopupModal;
