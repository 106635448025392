const sx = {
  root: {},

  modal: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    p: 1,
    m: 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "27px",
  },
  datePicker: {
    marginTop: "40px",
  },
  modalHeader: {
    marginTop: "60px",
  },
  modalButton: {
    border: "2px solid #F64C04",
    backgroundColor: "unset",
    color: "#F64C04",
    margin: "0px",
    boxShadow: "unset",
    marginY: "40px",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
    "&:active": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
  },
  postedButton: {
    border: "2px solid #F64C04",
    backgroundColor: "unset",
    color: "#F64C04",
    margin: "0px",
    boxShadow: "unset",
    marginY: "40px",
    "&:hover": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
    "&:active": {
      border: "2px solid #F64C04",
      backgroundColor: "#F64C04",
      color: "#fafaf2",
    },
  },
  closeButton: {
    width: "17.5px",
    height: "17.5px",
    position: "absolute",
    right: "38px",
    top: "37px",
    cursor: "pointer",
  },
};

export default sx;
