import { Box, Typography } from "@mui/material";
import React from "react";
import RestartTransactionButton from "./components/RestartTransactionButton";

const BP2 = "@media (max-width: 699px)";

const sx = {
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontWeight: "bold",
    color: "#F64C04",
    fontSize: "38px",
    [BP2]: {
      fontSize: "30px",
    },
  },
  message: {
    mt: "10px",
    [BP2]: {
      fontSize: "16px",
      mt: "10px",
    },
  },
};

const ThankYou = ({ message, variant }) => {
  if (!message) {
    return null;
  }

  return (
    <>
      <Box sx={sx.flex}>
        <Typography sx={sx.header}>THANK YOU!</Typography>
        <Typography sx={sx.message}>{message}</Typography>

        <Box sx={{ alignItem: "flex-end", mt: "20px" }}>
          {variant === "restart" && <RestartTransactionButton />}
        </Box>
      </Box>
    </>
  );
};

export default ThankYou;
