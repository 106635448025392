import { Typography, Box } from "@mui/material";
import React, { useContext } from "react";
import ConfigContext from "../context/ConfigContext";

const sx = {
  description: {
    margin: "auto",
    maxWidth: "600px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#C2C4B2",
    textAlign: "center",
    lineHeight: "1.8",
  },
  root: {
    width: "100%",
    height: "100%",
    background: "none",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    textAlign: "center",
    color: "#F64C04",
    lineHeight: "34px",
    fontSize: "30px",
  },
  url: {
    textDecoration: "none",
    color: "#F64C04",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
};

const DisplayTxBox = ({ txHash, displayWhen }) => {
  const config = useContext(ConfigContext);

  if (!displayWhen) {
    return null;
  } else {
    return (
      <Typography
        sx={sx.description}
        style={{
          position: "relative",
          fontSize: "16px",
          maxWidth: "600px",
          lineHeight: "30px",
        }}
      >
        {txHash && (
          <>
            You can check the transaction status on{" "}
            <Box
              component="a"
              href={`${config.ETHERSCAN_URL}/tx/${txHash}`}
              sx={sx.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Etherscan
            </Box>
            .
          </>
        )}
      </Typography>
    );
  }
};

export default DisplayTxBox;
