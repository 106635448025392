import { displayError } from "galaxis-utraits-client-common";
import { default as axios } from "../api";

const getRedeemState = async (tokenId, traitId, wallet) => {
  //console.log(tokenId, traitId, wallet);
  if (tokenId === null || traitId === null || !wallet) {
    return null;
  }

  const data = {
    tokenId,
    traitId,
    signerAddress: wallet.accounts[0].address,
  };

  try {
    const response = await axios.post("/api/redeems/state", data);
    return response.data;
  } catch (err) {
    displayError(err);
    return null;
  }
};

/**
 * Find object value by path
 * @param {*} obj
 * @param {*} path
 * @returns object | null
 */
function deepFind(obj, path) {
  var paths = path.split("."),
    current = obj,
    i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}

export { getRedeemState, deepFind };
