import { Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { grey } from "@mui/material/colors";

var moment = require("moment");

const dateColumn = {
  type: "date",
  flex: 0.5,
  align: "center",
  headerAlign: "center",
  headerName: "MEETING AT",
  renderCell: (params) => {
    const eventDate = moment(params.value).calendar();
    const diff = moment(params.value).diff(moment(), "days");
    return `${eventDate} (${diff} days left)`;
  },
};

const openJitsimeet = (params) => {
  window.open(`https://meet.jit.si/${params.value}`);
};

const sx = {
  linkButtonView: {
    "&:hover": {
      backgroundColor: grey[500],
      borderColor: grey[700],
      color: "white",
    },
    borderRadius: "3px",
    fontFamily: "poppins",
    fontSize: 16,
    color: "white",
    backgroundColor: grey[300],
    borderColor: grey[300],
    p: 0,
    px: 1,
    textTransform: "none",
  },
};

const joinColumn = {
  type: "string",
  flex: 0.5,
  align: "center",
  headerAlign: "center",
  headerName: "ACTION",
  renderCell: (params) => (
    <Button
      sx={sx.linkButtonView}
      variant="outlined"
      title="Join meeting"
      onClick={(params) => openJitsimeet(params)}
    >
      <LaunchIcon />
    </Button>
  ),
};

const columns = [
  {
    field: "tokenId",
    headerName: "TOKEN ID",
    width: 90,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "traitId",
    headerName: "TRAIT ID",
    width: 90,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "signerAddress",
    headerName: "TOKEN OWNER",
    flex: 0.7,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "to",
    headerName: "E-MAIL",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
  },
  { field: "startDate", ...dateColumn },
  { field: "calendarEventId", ...joinColumn },
  {
    field: "bookedStatus",
    headerName: "BOOK STATUS",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
  },
];

export default columns;
