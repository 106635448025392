import { Box, FormControl, Input, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import meeting from "../../assets/images/traits/meeting-black.svg";
import meetingDark from "../../assets/images/traits/meeting-orange.svg";
import axios from "../../api";
import { SpinnerCircular } from "spinners-react";
import { isEmail } from "class-validator";
import BookButton from "./Components/BookButton";
import HomeButton from "./Components/HomeButton";

import { signFormData } from "../../utils/signature-util";
import { displayError } from "galaxis-utraits-client-common";
import BackdropModal from "../BackdropModal";
import sx from "./Components/sx";
import { ModeCtx } from "../../context/modeCtx";
import ImageHolder from "./Components/ImageHolder";
import ConfiContext from "../../context/ConfigContext";

const moment = require("moment");

const MeetingClaim = ({
  isOpen,
  traitId,
  tokenId,
  redeemState,
  setRedeemState,
  setTxHash,
  contractService,
  txInProgress,
  setTxInProgress,
  txEtherScan,
  setTxEtherScan,
}) => {
  const modeCtx = useContext(ModeCtx);
  const config = useContext(ConfiContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDates, setAvaibleDates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [email, setEmail] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  //const [txInProgress, setTxInProgress] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);
  //const [txEtherScan, setTxEtherScan] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("ddd, MMM DD YYYY h:mm a");
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/events", {
        params: {
          status: ["open"],
        },
      })
      .then((response) => {
        let dates = [];
        response.data.events.forEach((event) => {
          const date = formatDate(event.start);
          dates.push({
            id: event.id,
            date: date,
            avaible: true,
            startDate: event.start,
            eventId: event.eventId,
          });
        });
        dates.sort((a, b) => {
          return (
            new Date(a.satartDate).getTime() < new Date(b.satartDate).getTime()
          );
        });
        setAvaibleDates(dates);
        //setAvaibleDates([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!email) {
      setIsValidEmail(false);
    }
    const isValid = isEmail(email);

    setIsValidEmail(isValid);
  }, [email]);

  //TODO Implement DUMMY data
  const bookEvent = async () => {
    console.log(selectedDate);
    console.log(availableDates);
    const selected = availableDates.filter((d) => d.id === selectedDate)[0];
    //return;
    const data = {
      tokenId: tokenId,
      traitId: traitId,
      calendarEventId: selectedDate,
      startDate: startDate,
      to: email,
      calendarEventIdHash: selected.eventId,
      //calendarEventIdHash: availableDates.eventId,
    };

    if (txInProgress) {
      return;
    }

    setTxInProgress(true);

    try {
      // No need to validate form data here (otherwise we can show errors before signature request)

      // Sign the full form data
      const signedFormData = await signFormData(data);
      console.log(signedFormData);
      if (signedFormData) {
        // Send the signed data for saving to backend
        await axios.post("/api/contacts/", signedFormData);

        // Burn the utility trait on chain (ask for transaction signature)
        // If it was externally/manually burned (maybe from etherscan) then we do not burn now, nor do we have txHash, just simply saved the contact to db
        console.log("redeemState:", redeemState);
        if (redeemState !== "MANUALLY_BURNED") {
          setApproveInProgress(true);
          const tx = await contractService.burn(
            tokenId,
            data.calendarEventIdHash
          );
          setApproveInProgress(false);
          // console.log("tx", tx);
          if (tx) {
            setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
            // setTxInProgress(true);
            // await tx.wait().catch(e=>{handleError(e);setTxInProgress(false);});
            await tx.wait();
            // setTxInProgress(false);
          }
          if (tx?.hash) {
            // console.log("Sending tx hash to backend");
            await axios.post("/api/contacts/add_txhash", {
              ...signedFormData,
              txHash: tx.hash,
            }); // Throws error if invalid
            setTxHash(tx.hash);
          }
        }
      }
      setTxInProgress(false);
      setRedeemState("CONFIRMED");
    } catch (err) {
      console.error(err);
      // --- Error handling ---
      setApproveInProgress(false);
      setTxInProgress(false);
      displayError(err);
    }
  };

  return (
    <>
      {/* <Box sx={sx.rootHolder}> */}
      <Box sx={sx.root}>
        <Box sx={sx.header}>
          <ImageHolder>
            <img
              src={modeCtx.lightTheme ? meeting : meetingDark}
              alt=""
              height={"100%"}
              width={"100%"}
            />
          </ImageHolder>
        </Box>

        <Box sx={sx.content}>
          <Typography sx={sx.title}>Online Meeting</Typography>
          {availableDates.length ? (
            <Typography sx={sx.description}>
              Please select an available time slot to chat with the creator of
              your Digital Collectible and press the ‘Book’ button.
            </Typography>
          ) : (
            <Typography sx={sx.description}>
              Currently, there is no available event in the artist's calendar.
              Kindly, check back later to find new appointment!
            </Typography>
          )}
        </Box>
        {availableDates.length ? (
          <>
            {/* <Box sx={sx.emailBox}> */}
            <FormControl sx={sx.emailBox}>
              <Input
                style={{ color: "black" }}
                id="physical-input"
                onChange={handleChange}
                placeholder="Enter email address"
              ></Input>
            </FormControl>
            {/* </Box> */}
            <SpinnerCircular
              size={60}
              thickness={145}
              speed={100}
              color="#F64C04"
              secondaryColor="rgba(172, 109, 57, 0.44)"
              enabled={loading}
            />
            <Box sx={sx.dateHolder}>
              {availableDates.map((item, index) => {
                if (item.date !== undefined) {
                  return (
                    <Box
                      sx={sx.date}
                      key={item.id}
                      style={{
                        //marginTop: 5,
                        backgroundColor: selectedDate === item.id && "#F64C04",
                        border: selectedDate === item.id && "1px solid #F64C04",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedDate(item.id);
                        setStartDate(item.startDate);
                      }}
                    >
                      <Typography
                        sx={sx.dateText}
                        style={{
                          fontFamily: selectedDate === index && "bau",
                          color: selectedDate === item.id && "#FCF7F7",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.date}
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
            <Box>
              <Typography style={{ marginTop: "10px", fontFamily: "bau" }}>
                You have selected:
              </Typography>
            </Box>

            <Box>
              <Typography sx={sx.selectedDate}>
                {formatDate(startDate)}
              </Typography>
            </Box>
          </>
        ) : null}

        <Box sx={sx.buttonHolder}>
          {availableDates.length ? (
            <BookButton
              bookEvent={bookEvent}
              selectedDate={selectedDate}
              isValidEmail={isValidEmail}
            />
          ) : (
            <HomeButton />
          )}
        </Box>
      </Box>
      {/* </Box> */}
      <BackdropModal isOpen={approveInProgress} />
    </>
  );
};

export default MeetingClaim;
