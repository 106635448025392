const Joi = require("joi");

const validateConfig = (config) => {
  const envVarsSchema = Joi.object()
    .keys({
      ENV: Joi.string().valid("main", "staging", "dev").required(),
      APP_TRAIT_TYPE: Joi.number(),
      CHAIN_ID: Joi.number().default(1),
      RPC_URL: Joi.string().required().description("RPC URL"),
      ETHERSCAN_URL: Joi.string().required().description("ETHERSCAN URL"),
      COMMUNITY_ID: Joi.number().default(1),
    })
    .unknown();

  const { error } = envVarsSchema
    .prefs({ errors: { label: "key" } })
    .validate(config);

  if (error) {
    throw new Error(`Config validation error: ${error.message}`);
  }
};

const getConfig = async () => {
  const res = await fetch("api/get_env");
  if (!res.ok) throw new Error(res.statusText);
  return res.json();
};

const initConfig = async () => {
  const config = await getConfig();

  validateConfig(config);

  localStorage.setItem("appConfig", JSON.stringify(config));

  return config;
};

export default initConfig;
